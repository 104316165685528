<template>
  <el-menu :default-active='$route.path' router background-color='#323A52' :unique-opened='true' :collapse='iscollapse'
    :collapse-transition='false' mode='vertical' text-color='#fff' active-text-color='#1890FF' class='menu'>
    <SidebarItem :data='menu'></SidebarItem>
  </el-menu>
</template>

<script>
import SidebarItem from '@/layout/components/SidebarItem.vue'
import data from './data.js'
export default {
  components: {
    SidebarItem
  },
  data() {
    return {
      data
      // menu: data
    }
  },
  computed: {
    iscollapse() {
      return this.$store.state.cookies.sidebar_status
    },
    menu() {
      const menu = this.$store.getters['optionsMenu/getMenuinfo']
      return menu
    }
    // menu() {
    //   const menu = this.$store.getters['optionsMenu/getHomeMenuinfo']?.children
    //   return menu
    // }
  },
  methods: {

  }
}
</script>

<style scoped>
.el-menu {
  border-right: none;
}

.el-menu-item {
  height: 40px;
  line-height: 40px;
  color: #fff;
}

.el-menu-item:hover {
  background-color: #3C445D !important;
}

.el-image {
  width: 14px;
  height: 14px;
}

:deep(.is-active) {
  background: #414A65 !important;
  opacity: 1;
}

span {
  margin-left: 14px;
}
</style>