<template>
  <div>
    <el-input ref='pwdInput' :type='pwdObj.pwdType' @focus='focusEnd($event)' :placeholder='placeholder'
      :maxlength='maxlength' v-model.trim='forms[password]'>
      <slot name='prepend' slot='prepend'></slot>
      <el-image slot='suffix' class='img-sty'
        :src="pwdObj.pwdType !== 'text' ? require('@/assets/components/PasswordInput/hiddenPassword.png') : require('@/assets/components/PasswordInput/displayPassword.png')"
        fit='scale-down' @click="changeye('pwdType', 'pwdInput')" />
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'WebPasswordInput',
  props: {
    form: {
      default: () => {
        return {}
      }
    },
    // 字段名
    password: {
      type: String,
      default: () => {
        return 'password'
      }
    },
    // 提示文字
    placeholder: {
      type: String,
      default: () => {
        return '设置6至20位的登录密码'
      }
    },
    // 限制输入长度
    maxlength: {
      type: Number,
      default: () => {
        return 20
      }
    }
  },
  computed: {
    forms() {
      return this.form
    }
  },
  data() {
    return {
      pwdObj: { pwdType: 'password' }
    }
  },

  methods: {
    // 点击图标控制密码的显示和隐藏
    changeye(typeName, refName) {
      this.$set(
        this.pwdObj,
        `${typeName}`,
        this.pwdObj[`${typeName}`] === 'password' ? 'text' : 'password'
      )
      this.$refs[`${refName}`].focus()
    },
    // 点击查看密码图标使光标定位到最后
    focusEnd(e) {
      // input获取光标显示在最后
      const obj = e.srcElement
      // 光标定位要加上 setTimeOut，不然就会重新光标定位失败
      setTimeout(() => {
        obj.selectionStart = obj.selectionEnd = obj.value.length
      }, 0)
    }
  }
}
</script>

<style lang='scss' scoped>
.img-sty {
  cursor: pointer;
  margin-top: 9px;
  height: 22px;
  width: 22px;
}
</style>