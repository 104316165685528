import Vue from 'vue'
import Vuex from 'vuex'
import optionsUser from '@/store/modules/optionsUser'
import optionsMenu from '@/store/modules/optionsMenu'
import optionsDict from '@/store/modules/optionsDict'
import cookies from '@/store/modules/cookies'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    // 用户信息
    optionsUser,
    // 菜单信息
    optionsMenu,
    // 字典信息
    optionsDict,
    // 菜单是否折叠
    cookies
  }
})
