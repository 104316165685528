import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout/index.vue'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/',
    component: layout,
    meta: {
      title: '首页模块'
    },
    children: [
      {
        path: '/',
        // 重定向
        redirect: '/homePage'
      },
      {
        path: '/homePage',
        component: () => import('@/views/homePage/index.vue'),
        meta: {
          title: '首页'
        }
      },
      {
        path: '/goodsCategory',
        component: () => import('@/views/goodsCategory/index.vue'),
        meta: {
          title: '商品分类'
        }
      },
      {
        path: '/goodsCategoryReclassify',
        component: () => import('@/views/goodsCategoryReclassify/index.vue'),
        meta: {
          title: '商品二级分类'
        }
      },
      {
        path: '/goodsSpecification',
        component: () => import('@/views/goodsSpecification/index.vue'),
        meta: {
          title: '商品规格'
        }
      },
      {
        path: '/goodsSpecificationProperty',
        component: () => import('@/views/goodsSpecificationProperty/index.vue'),
        meta: {
          title: '属性列表'
        }
      },
      {
        path: '/goodsList',
        component: () => import('@/views/goodsList/index.vue'),
        meta: {
          title: '商品列表'
        }
      },
      {
        path: '/goodsAddEdit',
        component: () => import('@/views/goodsAddEdit/index.vue'),
        meta: {
          title: '添加编辑商品'
        }
      },
      {
        path: '/goodsCheck',
        component: () => import('@/views/goodsCheck/index.vue'),
        meta: {
          title: '商品审核'
        }
      },
      {
        path: '/orderformList',
        component: () => import('@/views/orderformList/index.vue'),
        meta: {
          title: '订单列表'
        }
      },
      {
        path: '/orderformParticulars',
        component: () => import('@/views/orderformParticulars/index.vue'),
        meta: {
          title: '订单详情'
        }
      },
      {
        path: '/orderformShipments',
        component: () => import('@/views/orderformShipments/index.vue'),
        meta: {
          title: '批量发货'
        }
      },
      {
        path: '/orderformAftersales',
        component: () => import('@/views/orderformAftersales/index.vue'),
        meta: {
          title: '批量发货'
        }
      },
      {
        path: '/orderformInvoice',
        component: () => import('@/views/orderformInvoice/index.vue'),
        meta: {
          title: '订单开票'
        }
      },
      {
        path: '/orderformExpressage',
        component: () => import('@/views/orderformExpressage/index.vue'),
        meta: {
          title: '物流公司'
        }
      },
      {
        path: '/orderformSet',
        component: () => import('@/views/orderformSet/index.vue'),
        meta: {
          title: '订单设置'
        }
      },
      {
        path: '/clientList',
        component: () => import('@/views/clientList/index.vue'),
        meta: {
          title: '用户列表'
        }
      },
      {
        path: '/clientParticulars',
        component: () => import('@/views/clientParticulars/index.vue'),
        meta: {
          title: '用户详情'
        }
      },
      {
        path: '/schemeList',
        component: () => import('@/views/schemeList/index.vue'),
        meta: {
          title: '方案列表'
        }
      },
      {
        path: '/schemeAddEdit',
        component: () => import('@/views/schemeAddEdit/index.vue'),
        meta: {
          title: '添加编辑方案'
        }
      },
      {
        path: '/schemeClassify',
        component: () => import('@/views/schemeClassify/index.vue'),
        meta: {
          title: '分类管理'
        }
      },
      {
        path: '/statisticsMarket',
        component: () => import('@/views/statisticsMarket/index.vue'),
        meta: {
          title: '销售统计'
        }
      },
      {
        path: '/statisticsComprehensive',
        component: () => import('@/views/statisticsComprehensive/index.vue'),
        meta: {
          title: '综合统计'
        }
      },
      {
        path: '/exhibitionSlideshow',
        component: () => import('@/views/exhibitionSlideshow/index.vue'),
        meta: {
          title: '轮播图列表'
        }
      },
      {
        path: '/exhibitionCollaborate',
        component: () => import('@/views/exhibitionCollaborate/index.vue'),
        meta: {
          title: '合作伙伴列表'
        }
      },
      {
        path: '/permissionMenus',
        component: () => import('@/views/permissionMenus/index.vue'),
        meta: {
          title: '菜单管理'
        }
      },
      {
        path: '/permissionRole',
        component: () => import('@/views/permissionRole/index.vue'),
        meta: {
          title: '角色管理'
        }
      },
      {
        path: '/permissionAdmin',
        component: () => import('@/views/permissionAdmin/index.vue'),
        meta: {
          title: '管理员管理'
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
