// 引入ElementUI组件
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import plugins from '@/components'
import common from '@/mixin/common'
import * as util from '@/utils/util'
import * as echarts from 'echarts'
import '@/router/routerWards.js'
import * as directives from '@/directives'
import '@/assets/iconfont/iconfont.css'
// 全局初始化样式
import 'normalize.css'
import '@/styles/index.scss'

const MyPlugin = {
  install: function (Vue) {
    // 全局注册一个宽度
    Vue.prototype.labelWidth = '160px'
    // 引入图表
    Vue.prototype.$echarts = echarts
    // 引入ui框架
    Vue.use(ElementUI)
    // 全局注册一些组件
    Vue.use(plugins)
    // 全局混入
    Vue.mixin(common)
    // 工具函数
    Vue.prototype.util = util
    // 自定义指令
    Object.keys(directives).forEach(key => {
      Vue.directive(key, directives[key])
    })
  }
}
export default MyPlugin
