
/**
 * 删除对象里面的空值
 * @return 返回处理后的对象
 */
export const formatQuery = (query) => {
  for (const i in query) {
    if (!query[i] && query[i] !== 0) {
      delete query[i]
    }
  }
  return query
}
// json转url
export const jsonToUrl = (data) => {
  let ret = ''
  for (const it in data) {
    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
  }
  return ret
}
/**
 * tree转list
 * @param json 待解析的json串
 * @param temp 临时存放节点id的数组
 * @return 解析后的json
 */
export const treeToList = (tree, list = []) => {
  for (const item of tree) {
    if (item.children && item.children.length > 0) {
      treeToList(item.children, list)
    }
    list.push(item)
  }
  return list
}

/**
 * 居住地址对象
 * @param userAddress
 * @constructor
 */
export const FormattedAddress = (userAddress) => {
  if (!userAddress || !Object.keys(userAddress).length) {
    return ''
  }
  return '' + (userAddress.provinceName || '') + (userAddress.cityName || '') + (userAddress.districtName || '') + (userAddress.location || '')
}
/**
 * 时间处理
 * @param userAddress
 * @constructor
 */
export const getDate = (date) => {
  if (date) {
    return date
      .toString()
      .replace('T', ' ')
      .substring(0, 10)
  }
  return ''
}
/**
 * 时间处理
 * @param userAddress
 * @constructor
 */
export const getDateTime = (date) => {
  if (date) {
    return date
      .toString()
      .replace('T', ' ')
      .substring(0, 19)
  }
  return '无'
}

// 好像是分页那边用的
export const toPercent = (point, fixed = 2) => {
  let str = Number(point * 100).toFixed(fixed) || ''
  if (isNaN(str)) {
    return ''
  }
  str += '%'
  return str
}

/**
 * 对象转url参数
 * @return 返回处理后的参数
 */
export const jsonToUrlParam = (json) => {
  return Object.keys(json).map(key => key + '=' + json[key]).join('&')
}

/**
 * 日期格式化
 * 引入方法 import { dateFormat } from "@/utils/numberToCurrency";
 * @param fmt 格式"YYYY-mm-dd HH:MM"
 * @param date 日期
 */
export function dateFormat(fmt, date) {
  date = new Date(date)
  let ret
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
    }
  }
  return fmt
}

/**
 * 处理树数据最后一级没有数据将children变成undefined
 * @return 返回处理后的参数
 */
export function getTreeData(data) {
  for (let i = 0; i < data?.length; i++) {
    if (data[i].children?.length < 1) {
      // 最后一级没有数据将children变成undefined
      data[i].children = undefined
    } else {
      // children不为空时继续调用该方法
      this.getTreeData(data[i].children)
    }
  }
  return data
}