<template>
  <div class='header-box'>
    <div class="header-left">
      <el-image :src="require('@/assets/layout/logo.png')"></el-image>
      <span class="webSiteName">金锤服务商城管理系统</span>
      <i class='el-icon-s-fold header-icon' @click='fold'></i>
    </div>
    <div class='header-user' @click='logout'>
      <el-avatar :src="require('@/assets/layout/user.png')" size="medium"></el-avatar>
      <span class="user-name">
        {{ userInfo.name || '' }}
      </span>
      <el-image class="user-logout" :src="require('@/assets/layout/logout.png')"></el-image>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { UserModule } from '@/store/operation'
export default {
  name: 'HeaderName',
  computed: {
    ...mapState('optionsUser', ['userInfo']),
    getMenuinfoArr() {
      return this.$store.getters['optionsMenu/getMenuinfoArr']
    }
  },
  data() {
    return {
      // 访问过的链接
      pathList: [],
      is: null
    }
  },
  methods: {
    // 跳转
    gourl(val) {
      console.log(val)
      this.$router.push({
        path: val
      })
    },
    // 退出
    async logout() {
      UserModule.Logout()
      this.$router.push('/login')
    },
    fold() {
      this.$store.dispatch('cookies/setSidebarStatus')
    }
  }
}
</script>

<style lang='scss' scoped>
.header-box {
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(153, 153, 153, 0.1);
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  align-items: center;
}

.webSiteName {
  margin: 0 10px;
  font-size: 18px;
  font-family: Source Han Serif SC;
  font-weight: 500;
  color: #333333;
  // letter-spacing: 20px;
  opacity: 1;
}

.header-icon,
.user-logout {
  cursor: pointer;
}

.header-user {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.user-name {
  margin: 0 25px 0 10px;
  font-size: 16px;
  font-family: uniqueID∞version∞PingFangSC∞Medium;
  color: #333333;
  opacity: 1;
}
</style>