import Vue from 'vue'
// 限制只能输入数字，保留后两位，且不能输入多个"." jine
const amount =
  Vue.directive('amount', {
    inserted(el) {
      el.addEventListener('input', (e) => {
        let value = e.target.value
        value = value.replace(/[^\d.]/g, '')
          .replace(/\.{2,}/g, '.')
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.')
          .replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        e.target.value = value
        e.target.dispatchEvent(new Event('input'))// 更新v-model绑定的值
      })
    }
  })

export { amount }
