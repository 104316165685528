import UploadFileArray from '@/components/upload/upload-file-array.vue'
import UploadFile from '@/components/upload/upload-file.vue'
import PasswordInput from '@/components/PasswordInput/PasswordInput.vue'
function plugins(Vue) {
  // Vue.component('upload-file-array-object', UploadFileArrayObject)
  Vue.component('upload-file-array', UploadFileArray)
  Vue.component('upload-file', UploadFile)
  Vue.component('PasswordInput', PasswordInput)
}
export default plugins
