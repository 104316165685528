<template>
  <el-container class='my-container'>
    <el-header>
      <HeadBar></HeadBar>
    </el-header>
    <el-container>
      <el-aside :width='iscollapse ? " 60px" : "240px"'>
        <SlideBar></SlideBar>
      </el-aside>
      <el-main ref='appMain'>
        <AppMain ref='appMain'></AppMain>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import HeadBar from '@/layout/components/header.vue'
import SlideBar from '@/layout/components/sliderBar.vue'
import AppMain from '@/layout/components/AppMain.vue'
export default {
  name: 'FrameIndex',
  components: {
    HeadBar,
    SlideBar,
    AppMain
  },
  computed: {
    iscollapse() {
      return this.$store.state.cookies.sidebar_status
    }
  }
}
</script>

<style scoped>
.my-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.el-header {
  z-index: 999;
  padding: 0;
  height: 60px !important;
}

.el-main {
  padding: 20px;
  height: calc(100vh - 60px);
  flex: 1;
  overflow-y: auto;
  display: flex;
  transition: 0.5s;
  background-color: #F0F4F7;
}

.el-aside {
  background-color: #323A52;
  transition: 0.5s;
}

.main-left-body {
  flex-direction: column;
  background-color: #f0f2f5;
}
</style>