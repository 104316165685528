import axios from 'axios'
import { Loading, Message, MessageBox } from 'element-ui'
import { getTokenCookie, removeTokenCookie } from '@/utils/cookies'
import router from '@/router'
const createService = ({ baseURL = '/api', timeout = 10000, loadingTime = 1000, loading = true }) => {
  const service = axios.create({
    baseURL,
    timeout
  })
  let loadingInstance = null
  let timer = null
  const startLoading = () => {
    if (loading) {
      loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        customClass: 'create-isLoading',
        background: 'rgba(0, 0, 0, 0)'
      })
      timer = setTimeout(() => {
        loadingInstance.customClass = ''
      }, loadingTime)
    }
  }
  const stopLoading = () => {
    loadingInstance?.close()
    clearTimeout(timer)
  }
  const handleUnauthorized = () => {
    MessageBox.confirm('当前登录状态已过期，请重新登录', '错误', {
      confirmButtonText: '重新登录',
      showClose: false,
      showCancelButton: false,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      type: 'warning'
    }).then(() => {
      removeTokenCookie()
      router.push('/login')
    })
  }
  service.interceptors.request.use(
    async (config) => {
      startLoading()
      const token = getTokenCookie()
      if (token) config.headers.Authorization = token
      return config
    },
    error => Promise.reject(error)
  )
  service.interceptors.response.use(
    async (response) => {
      stopLoading()
      if (response.status) {
        const { data } = response
        if (data.success) {
          return data
        } else if (data.status === 403) {
          handleUnauthorized()
        } else {
          Message.error(data.msg || data.message)
          return data
        }
      }
      return response.data
    },
    async (error) => {
      console.log(error)
      stopLoading()
      if (error.response?.status === 401) {
        handleUnauthorized()
      } else if (error.response?.status === 404) {
        Message.error('请求无效接口,报错404')
      } else {
        Message.error(error.response?.data.msg || error.response?.data.message || error.message || '网络错误')
      }
      return error
    }
  )
  return service
}
export default createService