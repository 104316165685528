<template>
  <div>
    <el-upload :file-list="fileList" :action="uploadUrl" :on-success="onSuccess" :on-error="onError" :on-remove="onRemove"
      :before-upload="beforeUpload" :on-preview="onPreview" :headers="headers" :limit="1" :on-exceed="onExceed"
      :data="data" :key="key" v-bind="$attrs" v-on="$listeners">
      <el-button v-if="visibleButton" size="small" type="primary">
        上传图片
      </el-button>
      <slot slot="tip" name="con-button"></slot>
    </el-upload>
  </div>
</template>

<script>
import { getTokenCookie } from '@/utils/cookies'
import Compressor from 'compressorjs'

export default {
  name: 'IotUploadFile',
  inheritAttrs: false,
  props: {
    value: {
      type: String
    },
    title: {
      type: String
    },
    limitSize: {
      type: Number,
      default: 1
    },
    uploadType: {
      default: () => ['png', 'jpg', 'jpeg', 'pdf', 'doc', 'xlsx', 'zip']
    },
    visibleButton: {
      type: Boolean,
      default: true
    },
    isCompress: {
      type: Boolean,
      default: true
    },
    isCompressTwo: {
      type: String,
      default: '1'
    }
  },
  computed: {
    fileList: {
      get() {
        const fileList = []
        if (this.value) {
          fileList.push({
            name: this.title,
            url: this.value
          })
        }
        return fileList
      },
      set(newValue) {
        console.log(newValue)
      }
    }
  },
  data() {
    return {
      uploadUrl: process.env.VUE_APP_BASE_API + '/oss/jpgPictureUpload',
      headers: { Authorization: getTokenCookie() },
      data: { isCompress: this.isCompressTwo || 1 },
      key: 1
    }
  },
  methods: {
    async onError(err) {
      this.$message.error(err.message)
    },

    async onSuccess(response, file, fileList) {
      if (response.status) {
        this.$message.error(response.message)
        this.fileList = fileList.filter((item) => item.uid !== file.uid)
        this.key = Math.random()
        return
      }
      const files = this.value || []
      const url = response.data
      files.push(url)
      this.$emit('input', url)
    },

    async onRemove() {
      this.$emit('input', null)
    },

    onPreview(file) {
      let suffix = ''
      let url = ''
      let name = ''
      if (file.url) {
        url = file.url
        name = file.name
        suffix = url.substring(url.lastIndexOf('.'), url.length)
      } else {
        url = file.url
        name = file.name
        suffix = ''
      }

      const downloadElement = document.createElement('a')
      downloadElement.href = url
      downloadElement.target = '_blank'
      downloadElement.download = `${name}${suffix}`
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
    },

    onExceed() {
      this.$message.error('超出文件数量限制')
    },

    limit(file) {
      let isIMAGE = true
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = this.uploadType
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传文件格式不正确')
        isIMAGE = false
      }
      const isLimit = file.size / 1024 / 1024 < this.limitSize
      console.log(file, file.size / 1024 / 1024)
      if (!isLimit) {
        this.$message.error(`上传大小不能超过${this.limitSize}MB`)
      }
      return isLimit && isIMAGE
    },

    beforeUpload(file) {
      const compressorTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif']
      if (compressorTypes.includes(file.type) && this.isCompress) {
        return new Promise((resolve, reject) => {
          /* eslint-disable no-new */
          new Compressor(file, {
            quality: 0.8,
            maxWidth: 1080,
            maxHeight: 1920,
            success: (result) => {
              file = new File([result], file.name, {
                type: file.type,
                lastModified: Date.now()
              })
              if (!this.limit(file)) {
                reject(new Error('出现错误'))
              }
              resolve(file)
            }
          })
        })
      }
      return this.limit(file)
    }
  }
}
</script>

<style scoped></style>