// User -> token
// 设置菜单折叠
const sidebarStatusKey = 'sidebar_status'
export const getSidebarStatus = () => localStorage.getItem(sidebarStatusKey)
export const setSidebarStatus = (sidebarStatus) => localStorage.setItem(sidebarStatusKey, sidebarStatus)

const tokenKey = 'serve_store_token'
export const getTokenCookie = () => localStorage.getItem(tokenKey)
export const setTokenCookie = (token) => localStorage.setItem(tokenKey, token)
export const removeTokenCookie = () => localStorage.removeItem(tokenKey)

// 账号
const account = 'account_number'
export const getAccountNumber = () => localStorage.getItem(account)
export const setAccountNumber = (token) => localStorage.setItem(account, token)
export const removeAccountNumber = () => localStorage.removeItem(account)

// 密码
const password = 'password_number'
export const getPasswordNumber = () => localStorage.getItem(password)
export const setPasswordNumber = (token) => localStorage.setItem(password, token)
export const removePasswordNumber = () => localStorage.removeItem(password)
