<!--上传文件组件-->
<template>
  <div>
    <!-- :file-list="fileList"  -->
    <el-upload :file-list="fileList" :action="uploadUrl" :on-success="onSuccess" :on-error="onError"
      list-type="picture-card" :on-remove="onRemove" :before-upload="beforeUpload" :on-preview="onPreview"
      :headers="headers" :limit="limits" :on-exceed="onExceed" :data="data" :key="key" v-bind="$attrs" v-on="$listeners">
      <el-button v-if="visibleButton" size="small" type="primary">
        点击上传
      </el-button>
      <div slot="file" slot-scope="{file}">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)" title="查看">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span v-if="isDownload" class="el-upload-list__item-delete" @click="onPreview(file)" title="下载">
            <i class="el-icon-download"></i>
          </span>
          <span v-if="isRemove" class="el-upload-list__item-delete" @click="onRemove(file)" title="删除">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
      <slot slot="tip" name="con-button"></slot>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { getTokenCookie } from '@/utils/cookies'
import Compressor from 'compressorjs'
export default {
  name: 'IotUploadFile',
  inheritAttrs: false,
  props: {
    value: {
      default: () => []
    },
    title: {
      type: String
    },
    // 文件个数
    limits: {
      type: Number,
      default: 5
    },
    // 文件大小
    limitSize: {
      type: Number,
      default: 1
    },
    // 文件格式
    uploadType: {
      default: () => ['png', 'jpg', 'jpeg']
    },
    visibleButton: {
      type: Boolean,
      default: true
    },
    // 是否压缩图片
    isCompress: {
      type: Boolean,
      default: true
    },
    // 是否下载
    isDownload: {
      type: Boolean,
      default: true
    },
    // 是否删除
    isRemove: {
      type: Boolean,
      default: true
    },
    isCompressTwo: {
      type: String,
      default: '1'
    }
  },
  computed: {
    fileList: {
      get() {
        console.log('执行了')
        const files = JSON.parse(JSON.stringify(this.value))
        const fileList = []
        if (files && files.length > 0) {
          files.forEach((item, index) => {
            let isItem = true
            this.oldFileList.forEach((nape) => {
              if (nape.url === item || nape.response?.data === item) {
                fileList.push(nape)
                isItem = false
              }
            })
            if (isItem) {
              const obj = {}
              obj.name = this.title + (index + 1)
              obj.url = item
              fileList.push(obj)
            }
          })
        }
        return fileList
      },
      set(newValue) {
        console.log(newValue)
      }
    }
  },
  data() {
    return {
      oldFileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      uploadUrl: process.env.VUE_APP_BASE_API + '/oss/jpgPictureUpload',
      headers: { Authorization: getTokenCookie() },
      data: { isCompress: this.isCompressTwo || 1 },
      key: 1
    }
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    async onError(err) {
      this.$message.error(err.message)
    },

    async onSuccess(response, file, fileList) {
      this.oldFileList = fileList
      // console.log(response, file, fileList)
      if (response.status) {
        this.$message.error(response.message)
        this.fileList = fileList.filter(item => item.uid !== file.uid)
        // 为了解决上传失败后会显示一个无效文件的问题
        this.key = Math.random()
        return
      }
      const files = this.value || []
      const url = response.data
      files.push(url)
      this.$emit('input', files)
    },

    async onRemove(file) {
      const files = this.value.filter(item => {
        return item !== (file.response?.data || file.url)
      })
      console.log(files)
      this.$emit('input', files)
    },
    async onPreview(file) {
      console.log(file, '7777')
      let suffix = ''
      let url = ''
      let name = ''
      if (!file.url) {
        url = file.url
        name = file.name
        suffix = url.substring(url.lastIndexOf('.'), url.length)
      } else {
        url = file.url
        name = file.name
        suffix = '' // name里面已经包含了后缀
      }

      // 下载
      const downloadElement = document.createElement('a')
      downloadElement.href = url
      downloadElement.target = '_blank'
      downloadElement.download = `${name}${suffix}` // 下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 点击下载
      document.body.removeChild(downloadElement) // 下载完成移除元素
    },
    onExceed() {
      this.$message.error('超出文件数量限制') /**/
    },
    // 判断是否超过限制
    limit(file) {
      let isIMAGE = true
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = this.uploadType
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传文件格式不正确')
        isIMAGE = false
      }
      const isLimit = file.size / 1024 / 1024 < this.limitSize
      if (!isLimit) {
        this.$message.error(`上传大小不能超过${this.limitSize}MB`)
      }
      return isLimit && isIMAGE
    },
    beforeUpload(file) {
      const compressorTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif']
      // 压缩图片
      if (compressorTypes.includes(file.type) && this.isCompress) {
        return new Promise((resolve, reject) => {
          /* eslint-disable no-new */
          new Compressor(file, {
            quality: 0.8,
            maxWidth: 1080,
            maxHeight: 1920,
            success: (result) => {
              file = new File([result], file.name, {
                type: file.type,
                lastModified: Date.now()
              })
              if (!this.limit(file)) {
                reject(new Error(false))
              }
              resolve(file)
            }
          })
        })
      }
      return this.limit(file)
    }
  }
}
</script>

<style scoped></style>
