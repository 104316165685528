const data = [
  {
    iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
    name: '系统首页',
    hideStatus: true,
    link: '/homePage',
    children: []
  },
  {
    iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
    name: '商品管理',
    hideStatus: true,
    link: '/goodsManagement',
    children: [
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '商品列表',
        hideStatus: true,
        children: [],
        link: '/goodsList'
      },
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '商品审核',
        hideStatus: true,
        children: [],
        link: '/goodsCheck'
      },
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '商品分类',
        hideStatus: true,
        children: [],
        link: '/goodsCategory'
      },
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '商品规格',
        hideStatus: true,
        children: [],
        link: '/goodsSpecification'
      }
    ]
  },
  {
    iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
    name: '订单管理',
    hideStatus: true,
    link: '/orderformManagement',
    children: [
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '订单列表',
        hideStatus: true,
        children: [],
        link: '/orderformList'
      },
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '订单售后',
        hideStatus: true,
        children: [],
        link: '/orderformAftersales'
      },
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '订单开票',
        hideStatus: true,
        children: [],
        link: '/orderformInvoice'
      },
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '物流公司',
        hideStatus: true,
        children: [],
        link: '/orderformExpressage'
      },
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '订单设置',
        hideStatus: true,
        children: [],
        link: '/orderformSet'
      }
    ]
  },
  {
    iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
    name: '用户管理',
    hideStatus: true,
    link: '/orderformManagement',
    children: [
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '用户列表',
        hideStatus: true,
        children: [],
        link: '/clientList'
      }]
  },
  {
    iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
    name: '方案管理',
    hideStatus: true,
    link: '/schemeManagement',
    children: [
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '方案列表',
        hideStatus: true,
        children: [],
        link: '/schemeList'
      },
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '分类管理',
        hideStatus: true,
        children: [],
        link: '/schemeClassify'
      }]
  },
  {
    iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
    name: '统计报表',
    hideStatus: true,
    link: '/statisticsManagement',
    children: [
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '销售统计',
        hideStatus: true,
        children: [],
        link: '/statisticsMarket'
      },
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '综合统计',
        hideStatus: true,
        children: [],
        link: '/statisticsComprehensive'
      }]
  },
  {
    iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
    name: '展示管理',
    hideStatus: true,
    link: '/exhibitionManagement',
    children: [
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '轮播图列表',
        hideStatus: true,
        children: [],
        link: '/exhibitionSlideshow'
      },
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '合作伙伴列表',
        hideStatus: true,
        children: [],
        link: '/exhibitionCollaborate'
      }]
  },
  {
    iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
    name: '权限管理',
    hideStatus: true,
    link: '/permissionManagement',
    children: [
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '角色管理',
        hideStatus: true,
        children: [],
        link: '/permissionRole'
      },
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '管理员管理',
        hideStatus: true,
        children: [],
        link: '/permissionAdmin'
      },
      {
        iconUrl: 'http://smart-site-dev.oss-cn-shanghai.aliyuncs.com/file/2022/11/15/e32c5e35-1b4d-4a18-9f84-e4b489a30ae3.png',
        name: '菜单管理',
        hideStatus: true,
        children: [],
        link: '/permissionMenus'
      }]
  }
]
export default data