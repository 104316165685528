import request from '@/utils/request'
/**
 * 获取当前登录用户菜单
 * @param mobile
 * @returns {AxiosPromise}
 */
export const oauthGetMyMenu = (data) => {
  return request({
    url: '/oauth/getMyMenu',
    method: 'POST',
    data
  })
}
/**
 * 当前登录用户详情
 * @param
 * @returns {AxiosPromise}
 */
export const adminMyDetail = () => {
  return request({
    url: '/admin/my/detail',
    method: 'GET'
  })
}
/**
 * 所有编码信息字典表
 * @param
 * @returns {AxiosPromise}
 */
export const dictGetAllDictCodeList = () => {
  return request({
    url: '/dict/getAllDictCodeList',
    method: 'GET'
  })
}