// 判断按钮权限
import Vue from 'vue'
// import { MenuModule } from '@/store/modules/menu'
import store from '@/store/index.js'
const has = Vue.directive('has', {
  inserted: function (el, binding) {
    if (!permissionJudge(binding.value)) {
      if (el && el.parentNode) {
        el.parentNode.removeChild(el)
      }
    }

    function permissionJudge(value) {
      // 此处store.getters.getMenuBtnList代表vuex中储存的按钮菜单数据
      // let butRoles = MenuModule.butRoles
      const butRoles = store.state.optionsMenu.menubtnArr

      if (!butRoles) {
        // 此处暂时先改为true，
        return false
      }
      const list = butRoles || []
      for (const item of list) {
        if (item.code === value) {
          return true
        }
      }
      // 此处暂时先改为true，
      return false
    }
  }
})

export { has }
